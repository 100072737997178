import axios from 'axios';
import * as routes from './routes';
import { PRODUCTION_ENVIRONMENT } from '../constants/constants';

const GET_METHOD = 'GET';
const POST_METHOD = 'POST';

const callAPI = (path, method = GET_METHOD, data, params) => {
  if (method === POST_METHOD) {
    return axios
      .post(path, JSON.stringify(data), params)
      .then((res) => res.data);
  }

  if (method === GET_METHOD) {
    return axios.get(path, { params: data }).then((res) => res.data);
  }
};

export const getWidgetContent = (widgetID) =>
  callAPI(routes.GET_WIDGET_CONTENT, POST_METHOD, widgetID);
export const getVehicleByLicensePlate = (licensePlate) =>
  callAPI(routes.GET_VEHICLE_BY_LICENSE_PLATE, POST_METHOD, licensePlate);
export const getAttributeContent = () => callAPI(routes.GET_ATTRIBUTE_CONTENT);
export const getAllMakes = () => callAPI(routes.GET_ALL_MAKES);
export const getAllModels = (data) =>
  callAPI(routes.GET_ALL_MODELS, GET_METHOD, data);
export const getAllVehicles = (data) =>
  callAPI(routes.GET_ALL_VEHICLES, POST_METHOD, data);
export const getEstimatedValue = (data) =>
  callAPI(routes.GET_ESTIMATED_VALUE, POST_METHOD, data);
export const postSubmission = (data) =>
  callAPI(routes.MAKE_SUBMISSION, POST_METHOD, data);
export const verifyPhoneNumber = (data) =>
  callAPI(routes.PHONE_VERIFICATION, POST_METHOD, data);

export const sendEmptyResponseLog = (data) => {
  if (process.env.ENVIRONMENT === PRODUCTION_ENVIRONMENT) {
    return callAPI(routes.SEND_EMPTY_RESPONSE_LOG, POST_METHOD, data);
  }
};
export const sendRequestFailedLog = (data) => {
  if (process.env.ENVIRONMENT === PRODUCTION_ENVIRONMENT) {
    callAPI(routes.SEND_REQUEST_FAILED__LOG, POST_METHOD, data);
  }
};
export const sendResultListWithWebhook = (data) => {
  if (process.env.ENVIRONMENT === PRODUCTION_ENVIRONMENT) {
    callAPI(routes.SEND_RESULT_LIST_WITH_WEBHOOK, POST_METHOD, data);
  }
};
