<script>
  export let header;
  export let price;
  export let description;
  export let buttonText = 'Verkaufsoptionen anzeigen';
  export let onButtonClick;
</script>

<div class="Panel">
  <div class="Panel-content">
    <h3 class="Panel__header">{header}</h3>
    {#if price}<div class="Panel__price"><strong>{price}</strong></div>{/if}
    <p class="Panel__description">{description}</p>
    <button class="Panel__button" on:click={onButtonClick}>{buttonText}</button>
  </div>
</div>

<style>
  .Panel {
    @apply my-12 block px-6 w-full;
  }
  .Panel-content {
    @apply p-6 text-white text-left rounded-xl;
    background-color: var(--primary);
  }
  .Panel__header {
    @apply mb-2 font-bold text-sm;
  }
  .Panel__price {
    @apply mb-2 text-3xl;
  }
  .Panel__description {
    @apply text-xs;
    margin-bottom: 0.95rem;
  }
  .Panel__button {
    @apply px-6 py-3 font-bold text-sm bg-white rounded-lg outline-none;
    color: var(--primary);
  }
</style>
