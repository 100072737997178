<script>
  export let label = '';
  export let options;
</script>

<div class="ButtonSwitch">
  <h3 class="ButtonSwitch__label">{label}</h3>
  <div class="ButtonSwitch__btn-group">
    <button
      class="ButtonSwitch__btn-group__btn"
      on:click={options[0].onSelect}
      class:selected={options[0].isActive}>{options[0].name}</button
    >
    <button
      class="ButtonSwitch__btn-group__btn"
      on:click={options[1].onSelect}
      class:selected={options[1].isActive}>{options[1].name}</button
    >
  </div>
</div>

<style>
  .ButtonSwitch {
    @apply mb-6 px-6 text-sm text-left;
  }
  .ButtonSwitch__label {
    @apply font-semibold;
  }
  .ButtonSwitch__btn-group {
    @apply flex mt-1.5 justify-between items-center;
  }
  .ButtonSwitch__btn-group .selected {
    @apply text-white duration-300 ease-in-out;
    background-color: var(--primary);
  }
  .ButtonSwitch__btn-group__btn {
    @apply p-1.5 w-full text-sm rounded-lg outline-none bg-transparent transition-colors;
    width: calc(50% - 3px);
    color: var(--primary);
    border: 1px solid var(--primary);
  }
</style>
