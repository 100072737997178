<script>
  import Fade from './Fade.svelte';
  import LoadingAnimation from './LoadingAnimation.svelte';

  export let isOpen = false;
  export let isLoadingAnimation = false;
  export let onClose = null;
  export let title = '';
  export let buttonText = 'Back to Form';
</script>

<div class="fixed z-50">
  <Fade show={isOpen} duration={120}>
    <div class="Modal">
      {#if isLoadingAnimation}
        <LoadingAnimation />
      {:else}
        <div class="Modal-content">
          <div class="Modal__header">
            <h3 class="Modal__header__title">{title}</h3>
            {#if onClose}
              <div class="Modal__close-btn" on:click={onClose} />
            {/if}
          </div>
          <p class="Modal__description">
            <slot />
          </p>
          {#if onClose}
            <button class="Modal__btn" on:click={onClose}>{buttonText}</button>
          {/if}
        </div>
      {/if}
    </div>
  </Fade>
</div>

<style>
  .Modal {
    @apply px-6 flex items-center justify-center w-screen h-screen left-0 top-0 bg-gray-900 bg-opacity-70;
    max-width: 480px;
  }
  .Modal-content {
    @apply p-6 text-left bg-white rounded-lg;
  }
  .Modal__header {
    @apply relative;
  }
  .Modal__header__title {
    @apply mb-3 font-semibold text-2xl;
  }
  .Modal__close-btn {
    @apply absolute top-1/2 right-0 transform -translate-y-2/4 w-6 h-6 cursor-pointer z-50;
    background-image: url('/assets/icons/close.svg');
  }
  .Modal__description {
    @apply mb-3 text-sm;
  }
  .Modal__btn {
    @apply py-3 px-6 text-white text-sm rounded-lg outline-none;
    background-color: var(--light-1);
    transition: all 0.3s ease;
  }
</style>
