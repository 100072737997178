<script>
  export let options;
  export let name;
  export let onSelect;
  export let openModal = null;

  let selectedOption = null;
  let resultPanel;

  const onSelectOption = (e, index) => {
    const radioBtn = resultPanel.querySelectorAll(
      '.ResultPanel__option__radio'
    )[index];

    // link block shouldn't trigger select event
    if (e.target) {
      const targetClassList = Array.from(e.target.classList);
      const ignoreEventClasses = [
        'ResultPanel__option__details__link__icon',
        'ResultPanel__option__details__link__link',
      ];
      const shouldBeIgnored = !!targetClassList.filter(
        className => ignoreEventClasses.indexOf(className) !== -1
      ).length;

      if (shouldBeIgnored) {
        return;
      }
    }

    selectedOption = index;
    radioBtn.checked = true;

    onSelect(options[index].value);
  };
</script>

<div class="ResultPanel" bind:this={resultPanel}>
  {#each options as option, i}
    <div
      class="ResultPanel__option {selectedOption === i ? 'selected' : ''}"
      on:click={e => (options.length > 1 ? onSelectOption(e, i) : null)}
    >
      <div>
        <input
          class="ResultPanel__option__radio"
          type="radio"
          {name}
          checked={options.length === 1 ? true : false}
          value={option.value}
        />
      </div>
      <div class="ResultPanel__option__details">
        <div class="ResultPanel__option__details__header">
          <h3 class="ResultPanel__option__details__header__header">
            {option.title}
          </h3>
          {#if option.badge}
            <div class="ResultPanel__option__details__header__badge">
              {option.badge}
            </div>
          {/if}
        </div>
        <p
          class="ResultPanel__option__details__hint"
          contenteditable="true"
          bind:innerHTML={option.hint}
        />
        {#if openModal}
          <div class="ResultPanel__option__details__link">
            <img
              src="assets/icons/info.svg"
              class="ResultPanel__option__details__link__icon"
              alt="info"
            />
            <div
              class="ResultPanel__option__details__link__link"
              on:click={() => openModal(option.modalIndex)}
            >
              {option.link}
            </div>
          </div>
        {/if}
      </div>
    </div>
  {/each}
</div>

<style>
  .ResultPanel {
    @apply mb-6 text-left cursor-pointer;
  }
  .ResultPanel__option {
    @apply flex px-6 py-3;
  }
  .ResultPanel__option__radio {
    @apply relative mr-3 w-6 h-6 invisible cursor-pointer;
    -moz-appearance: initial;
  }
  .ResultPanel__option__radio:after {
    @apply relative inline-block visible w-6 h-6 top-0 left-0 bg-transparent rounded-full;
    content: '';
    border-width: 1px;
    border-color: var(--light-2);
  }
  .ResultPanel__option__radio:checked:before {
    @apply relative inline-block visible w-6 h-6 top-0 left-0 rounded-full;
    content: '';
    background-color: var(--primary);
    border-width: 1px;
    border-color: var(--primary);
  }
  .ResultPanel__option__radio:checked:after {
    @apply absolute w-3 h-3 top-1/2 left-1/2 transform -translate-x-2/4 -translate-y-2/4 bg-white border-none rounded-full;
    content: '';
  }
  .ResultPanel__option__details__header {
    @apply flex items-center;
  }
  .ResultPanel__option__details__header__header {
    @apply font-semibold;
  }
  .ResultPanel__option__details__header__badge {
    @apply ml-1.5 px-2 py-0.5 flex items-center text-xs text-white rounded-md;
    background-color: var(--primary);
    transform: translateY(1px);
  }
  .ResultPanel__option__details__hint {
    color: var(--light-2);
    pointer-events: none;
  }
  .selected {
    color: var(--secondary);
    background-color: var(--light-2);
  }
  .selected .ResultPanel__option__details__header__badge {
    color: white;
    background-color: var(--light-1);
  }
  .selected .ResultPanel__option__details__hint,
  .selected .ResultPanel__option__details__link__link {
    color: var(--secondary);
  }
  .ResultPanel__option__details__link {
    @apply mt-2 flex items-center;
  }
  .ResultPanel__option__details__link__link {
    @apply ml-2.5 underline;
    color: var(--light-2);
  }
</style>
