<script>
  export let label;
  export let placeholder = 'Bitte auswählen...';
  export let options;
  export let hint = '';
  export let onSelect;

  let value = '';
</script>

<style>
  .SelectField {
    @apply mb-6 px-6 text-sm text-left;
  }
  .SelectField__label {
    @apply font-semibold;
  }
  .SelectField__select {
    @apply my-1.5 p-3.5 w-full border rounded-lg shadow outline-none;
    color: var(--secondary);
    border-color: var(--light-2);
    appearance: none;
    -webkit-appearance: none;
    background: transparent;
    background-image: url('/assets/icons/down.svg');
    background-repeat: no-repeat;
    background-position-x: calc(100% - 10px);
    background-position-y: 12px;
  }
  .SelectField__hint {
    color: var(--light-1);
  }
</style>

<div class="SelectField">
  <h3 class="SelectField__label">{label}</h3>
  <!-- svelte-ignore a11y-no-onchange -->
  <select
    bind:value
    class="SelectField__select"
    on:change={() => onSelect(value)}>
    <option value="" disabled selected>{placeholder}</option>
    {#each options as option}
      <option value={option.value}>{option.name}</option>
    {/each}
  </select>
  {#if hint}
    <p class="SelectField__hint">{hint}</p>
  {/if}
</div>
