export const GET_WIDGET_CONTENT = `${process.env.API_INTERNAL_PATH}/getWidgetContent`;
export const GET_VEHICLE_BY_LICENSE_PLATE = `${process.env.API_INTERNAL_PATH}/getVehicleByLicensePlate`;
export const GET_ATTRIBUTE_CONTENT = `${process.env.API_INTERNAL_PATH}/getAttributes`;
export const GET_ALL_MAKES = `${process.env.API_INTERNAL_PATH}/getAllMakes`;
export const GET_ALL_MODELS = `${process.env.API_INTERNAL_PATH}/getAllModels`;
export const GET_ALL_VEHICLES = `${process.env.API_INTERNAL_PATH}/getAllVehicles`;
export const GET_ESTIMATED_VALUE = `${process.env.API_INTERNAL_PATH}/getEstimatedValue`;
export const PHONE_VERIFICATION = `${process.env.API_INTERNAL_PATH}/phoneVerification`;

export const MAKE_SUBMISSION = `${process.env.API_INTERNAL_PATH}/makeSubmission`;
export const SEND_EMPTY_RESPONSE_LOG =
  'https://hooks.zapier.com/hooks/catch/7104846/o7lxexu/';
export const SEND_REQUEST_FAILED__LOG =
  'https://hooks.zapier.com/hooks/catch/7104846/o7lxexu/';
export const SEND_RESULT_LIST_WITH_WEBHOOK =
  'https://hooks.zapier.com/hooks/catch/7104846/b3qjbbi/';
