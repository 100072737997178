<script>
  export let id;
  export let label;
  export let placeholder;
  export let hint;
</script>

<div class="TextAreaField">
  <h3 class="TextAreaField__label">{label}</h3>
  <textarea {id} class="TextAreaField__area" rows="2" {placeholder} />
  {#if hint}
    <p class="TextAreaField__hint">{hint}</p>
  {/if}
</div>

<style>
  .TextAreaField {
    @apply mb-6 px-6 text-sm text-left;
  }
  .TextAreaField__label {
    @apply font-semibold;
  }
  .TextAreaField__area {
    @apply my-1.5 p-3.5 w-full border rounded-lg shadow outline-none;
    border-color: var(--light-2);
  }
  .TextAreaField__area::placeholder {
    color: var(--light-1);
  }
  .TextAreaField__hint {
    @apply text-gray-600;
  }
</style>
