<script>
  import { onMount } from 'svelte';

  export let hint = '';
  export let resendLabel = '';
  export let btnText = '';
  export let editPhoneNumberLabel = '';
  export let onEditPhoneNumber = () => {};
  export let onResend = () => {};

  let countdown = 60;
  let interval;

  onMount(() => {
    interval = setInterval(() => {
      if (countdown > 0) {
        countdown -= 1;
      } else {
        clearInterval(interval);
      }
    }, 1000);
  });

  const resetCountdown = () => {
    countdown = 60;
    clearInterval(interval);
    interval = setInterval(() => {
      if (countdown > 0) {
        countdown -= 1;
      } else {
        clearInterval(interval);
      }
    }, 1000);
  };
</script>

<div>
  <button
    class="block mb-1.5 underline"
    style="color: var(--primary)"
    on:click={onEditPhoneNumber}
  >
    {editPhoneNumberLabel}
  </button>
  <div>
    {hint}
    {#if countdown > 0}
      {resendLabel.replace('{countdown}', countdown)}
    {:else}
      <button
        class="underline"
        style="color: var(--primary)"
        on:click={() => {
          onResend();
          resetCountdown();
        }}>{btnText}</button
      >
    {/if}
  </div>
</div>
