<script>
  import { fade } from 'svelte/transition';

  export let show = false;
  export let duration = 300;
</script>

{#if show}
  <div transition:fade={{ duration }}>
    <slot />
  </div>
{/if}
