<script>
  import { onMount } from 'svelte';
  import smoothscroll from 'smoothscroll-polyfill';

  import Headline from './components/Headline.svelte';
  import BodyText from './components/BodyText.svelte';
  import Navbar from './components/Navbar.svelte';
  import SelectField from './components/SelectField.svelte';
  import InputField from './components/InputField.svelte';
  import TextAreaField from './components/TextAreaField.svelte';
  import ResultPanel from './components/ResultPanel.svelte';
  import Panel from './components/Panel.svelte';
  import ButtonSwitch from './components/ButtonSwitch.svelte';
  import Button from './components/Button.svelte';
  import Modal from './components/Modal.svelte';
  import LoadingAnimation from './components/LoadingAnimation.svelte';
  import Fade from './components/Fade.svelte';
  import CodeRetry from './components/CodeRetry.svelte';

  import {
    getWidgetContent,
    getAttributeContent,
    getAllMakes,
    getAllModels,
    getAllVehicles,
    getEstimatedValue,
    postSubmission,
    sendEmptyResponseLog,
    sendResultListWithWebhook,
    sendRequestFailedLog,
    getVehicleByLicensePlate,
    verifyPhoneNumber,
  } from './api/api';
  import {
    extractContentString,
    extractFormFieldString,
    getAttributeNamesByKey,
    formatNavbarContent,
    formatSellOptions,
    formatMakes,
    formatYears,
    formatDistinctByField,
    formatRelevantVehicles,
    formatEstimatedPrice,
    getRelevantOptions,
    formatGeneralOptions,
    LightenDarkenColor,
    hexToRGB,
  } from './utils/utils';
  import {
    SUPPORTED_LANGUAGES,
    FINAL_STEP,
    FLOW_LEAD_TYPE,
    FLOW_SELL_TYPE,
    MAKES,
    MONTHS,
    BODY_TYPES,
    FUEL_TYPES,
    GEAR_TYPES,
    CONDITION_TYPES,
    EQUIPMENT_TYPES,
    DEFAULT_THEME,
    LS_KEY_PHONE,
  } from './constants/constants';

  const SKIP_SALE_OPTIONS_STEP = true;
  const INITAL_PHONE_VERIFICATION = {
    isLoading: false,
    codeSent: false,
    invalidCode: false,
    phone: localStorage.getItem(LS_KEY_PHONE),
    token: null,
  };

  const state = {
    selectedFields: {
      make: null,
      model: null,
      year: null,
      month: null,
      vehicleParams: {
        bodyType: null,
        fuel: null,
        gear: null,
        type: null,
      },
      vehicleId: null,
      mileage: null,
      condition: null,
      equipment: null,
      whenToSell: null,
      sellOption: SKIP_SALE_OPTIONS_STEP ? 'fixprice' : null,
      contacts: {
        email: null,
        firstName: null,
        lastName: null,
        phone: null,
      },
    },
    makes: [],
    models: [],
    vehicles: { all: [], bodyTypes: [], fuels: [], gears: [], types: [] },
    estimatedPrice: null,
    currentStep: 0,
    skippedSteps: [],
    openedModals: [false, false, false, false, false, false],
    lang: 'de',
    flow: FLOW_SELL_TYPE,
    isLoading: true,
    currentStepName: '',
    entryType: 'plate-number',
    theme: DEFAULT_THEME,
    phoneVerification: INITAL_PHONE_VERIFICATION,
  };

  let widget;
  let widgetContent = null;
  // translations for keys in gear, fuel, etc. fields
  let attributeContent = null;
  let widget_key = null;
  let isBrandedVersion = false;
  let _isMounted = false;
  let isShrinkedNavbar = {
    value: false,
    triggerStep: null,
    isTriggeredScroll: false,
  };
  let redirectUrl;
  let plateNumber;

  onMount(() => {
    smoothscroll.polyfill();

    const urlParams = new URLSearchParams(window.location.search);
    const lang = urlParams.get('lang');
    const id = urlParams.get('id');
    const flow = urlParams.get('flow');

    if (id !== '1') {
      isBrandedVersion = true;
    }

    if (SUPPORTED_LANGUAGES.indexOf(lang) !== -1) {
      state.lang = lang;
    }

    if (flow === FLOW_LEAD_TYPE) {
      state.flow = flow;
    }

    if (!id) {
      return alert('No widget ID is provided!');
    }

    widget_key = id;

    try {
      const cachedWidgetContent = JSON.parse(
        localStorage.getItem('widgetContent')
      );
      const cachedMakes = JSON.parse(localStorage.getItem('makes'));

      if (
        cachedWidgetContent &&
        cachedMakes &&
        cachedWidgetContent.id === +widget_key
      ) {
        widgetContent = cachedWidgetContent;
        state.makes = cachedMakes;

        const {
          colors: { primary, secondary },
          logo: {
            data: {
              attributes: { url: logo },
            },
          },
        } = cachedWidgetContent.brand;
        state.theme = {
          primary,
          secondary,
          logo,
        };
        setCMSColorTheme(state.theme);

        _isMounted = true;
        state.isLoading = false;
      }
    } catch {
      state.isLoading = true;
      _isMounted = false;
    }

    onGetAllMakes();
  });

  $: {
    if (state.skippedSteps.indexOf(state.currentStep) !== -1) {
      let newStep = state.currentStep + 1;

      while (state.skippedSteps.indexOf(newStep) !== -1) {
        newStep++;
      }

      state.currentStep = newStep;
    }
  }

  /* --- GET DATA FROM API --- */

  const onGetAllMakes = (isSecondTry = false) =>
    getWidgetContent(widget_key)
      .then((data) => {
        widgetContent = data;

        const {
          colors: { primary, secondary },
          logo: {
            data: {
              attributes: { url: logo },
            },
          },
        } = data.brand;
        state.theme = {
          primary,
          secondary,
          logo,
        };
        setCMSColorTheme(state.theme);

        try {
          localStorage.setItem('widgetContent', JSON.stringify(data));
        } catch {}

        const widgetModalTranslations = {};

        widgetContent.feedback_modal.map((el) => {
          widgetModalTranslations[el.key] = el[state.lang];
        });

        window.parent.postMessage(
          {
            type: 'initialization',
            payload: {
              t: widgetModalTranslations,
              primaryColor: hexToRGB(state.theme.primary),
            },
          },
          '*'
        );

        return getAttributeContent();
      })
      .then((data) => {
        attributeContent = data;

        return getAllMakes();
      })
      .then((data) => {
        const formattedMakes = formatMakes(data);

        state.makes = formattedMakes;

        try {
          localStorage.setItem('makes', JSON.stringify(formattedMakes));
        } catch {}

        _isMounted = true;
        state.isLoading = false;
      })
      .catch((err) => {
        if (!isSecondTry && (!err.response || err.response.status === 502)) {
          return onGetAllMakes(true);
        }

        sendRequestFailedLog(err);
        alert(
          'Unfortunately something went wrong! Please try again later or contact us.'
        );
      });

  const onGetVehicleByLicensePlate = () => {
    if (!plateNumber) {
      return;
    }

    getVehicleByLicensePlate(plateNumber)
      .then((data) => {
        if (!data || !data.length || !data[0].bodyType) {
          throw new Error('No data');
        }

        state.vehicles.all = data;

        if (data.length > 1) {
          return handleGoNext(7);
        }

        parseSelectedFields(data[0]);
        state.selectedFields.vehicleId = data[0].articleKey;
        return handleGoNext(8);
      })
      .catch(() => {
        const log = {
          plateNumber,
        };

        sendEmptyResponseLog(log);
        onModalOpen(4);
      });
  };

  const parseSelectedFields = (vehicle) => {
    const {
      brand,
      model,
      firstRegistrationDate,
      bodyType,
      fuel,
      gear,
      type,
      articleKey,
    } = vehicle;

    let make;

    for (let key in MAKES) {
      if (MAKES[key] === brand) {
        make = key;
        break;
      }
    }

    const vehicleData = {
      make,
      model,
      year: firstRegistrationDate.split('.')[2],
      month: Number.parseInt(firstRegistrationDate.split('.')[1]) - 1,
      vehicleParams: {
        bodyType,
        fuel,
        gear,
        type,
      },
      vehicleId: articleKey,
    };

    state.selectedFields = {
      ...state.selectedFields,
      ...vehicleData,
    };

    sendResultLog();
  };

  const onGetAllModels = (isSecondTry = false) => {
    state.isLoading = true;
    const selectedMake = state.selectedFields.make;

    getAllModels({ brandCode: selectedMake })
      .then((data) => {
        state.models = formatDistinctByField(data, 'name', true);
        state.isLoading = false;
        scrollToBottom();
      })
      .catch((err) => {
        if (!isSecondTry && (!err.response || err.response.status === 502)) {
          return onGetAllModels(true);
        }

        sendRequestFailedLog(err);
        alert(
          'Unfortunately something went wrong! Please try again later or contact us.'
        );
      });
  };

  const onGetVehicles = (isSecondTry = false) => {
    state.isLoading = true;
    const { make, model, year } = state.selectedFields;

    const queryData = {
      brand: make,
      model,
      firstRegistrationYear: year,
      type: 'code',
    };

    return getAllVehicles(queryData)
      .then((data) => {
        if (!data || !data.length) {
          state.isLoading = false;

          const log = {
            brand: MAKES[make],
            model,
            first_registration_year: year,
          };

          sendEmptyResponseLog(log);

          return onModalOpen(2);
        }

        state.vehicles = {
          bodyTypes: formatDistinctByField(data, 'bodyType'),
          fuels: formatDistinctByField(data, 'fuel'),
          gears: formatDistinctByField(data, 'gear'),
          types: formatDistinctByField(data, 'type'),
        };

        let i = 4;
        const skippedSteps = [];

        for (let field in state.vehicles) {
          if (state.vehicles[field].length === 1) {
            // field contains plural 's' in the end
            state.selectedFields.vehicleParams[field.slice(0, -1)] =
              state.vehicles[field][0].value;
            skippedSteps.push(i);
          }
          i++;
        }

        state.vehicles.all = data;
        state.skippedSteps = skippedSteps;
        state.isLoading = false;

        scrollToBottom();
      })
      .catch((err) => {
        if (!isSecondTry && (!err.response || err.response.status === 502)) {
          return onGetVehicles(true);
        }

        sendRequestFailedLog(err);
        alert(
          'Unfortunately something went wrong! Please try again later or contact us.'
        );
      });
  };

  const onGetEstimatedValue = (isSecondTry = false) => {
    state.isLoading = true;
    const {
      make,
      model,
      month,
      year,
      vehicleParams,
      vehicleId,
      mileage,
      condition,
      equipment,
    } = state.selectedFields;
    const monthToString = (month < 9 ? '0' : '') + (month + 1);

    return getEstimatedValue({
      articleKey: vehicleId,
      serverType: 'Autodati',
      km: mileage,
      recipient: 'cadirect',
      equipmentPrice: '0',
      firstRegistrationDate: `01.${monthToString}.${year}`,
    })
      .then((data) => {
        if (!data || !data.estimationPrice) {
          state.isLoading = false;

          const log = {
            brand: MAKES[make],
            model,
            first_registration_month: MONTHS[month],
            first_registration_year: year,
            ...vehicleParams,
            vehicleId,
            mileage,
            condition: CONDITION_TYPES[condition],
            equipment: EQUIPMENT_TYPES[equipment],
          };

          for (let key in log) {
            if (!log[key]) {
              delete log[key];
            }
          }

          sendEmptyResponseLog(log);
          onModalOpen(3);

          return;
        }

        state.estimatedPrice = formatEstimatedPrice(
          data.estimationPrice,
          condition,
          equipment,
          widgetContent.settings
        );
        state.isLoading = false;
        scrollToBottom();
      })
      .catch((err) => {
        if (!isSecondTry && (!err.response || err.response.status === 502)) {
          return onGetEstimatedValue(true);
        }

        sendRequestFailedLog(err);
        alert(
          'Unfortunately something went wrong! Please try again later or contact us.'
        );
      });
  };

  /* --- MODAL HANDLERS --- */

  const onModalOpen = (index) => (state.openedModals[index] = true);
  const onModalClose = (index) => (state.openedModals[index] = false);

  /* --- WIDGET LOGIC --- */

  const sendAnalytics = (label) => {
    gtag('event', 'click', {
      event_category: 'widget',
      event_label: label,
    });

    state.currentStepName = label;
  };

  const setCMSColorTheme = (colors) => {
    const { primary, secondary } = colors;
    const root = document.documentElement;

    root.style.setProperty('--primary', primary);
    root.style.setProperty('--secondary', secondary);
    root.style.setProperty('--light-1', LightenDarkenColor(secondary, 75));
    root.style.setProperty('--light-2', LightenDarkenColor(secondary, 150));
  };

  const onSubmit = (isSecondTry = false) => {
    const { estimatedPrice, selectedFields } = state;
    const {
      make,
      model,
      year,
      month,
      vehicleParams,
      vehicleId,
      mileage,
      condition,
      equipment,
      whenToSell,
      sellOption,
      contacts,
    } = selectedFields;
    const { bodyType, fuel, gear, type } = vehicleParams;
    const { firstName, lastName, email, phone } = contacts;

    const formattedEstimatedPrice = estimatedPrice
      ? estimatedPrice.replace(/’/g, '')
      : '';
    const priceFrom = formattedEstimatedPrice
      ? Number.parseInt(
          formattedEstimatedPrice.split(' - ').shift().split('CHF ').pop()
        )
      : 0;
    const priceTo = formattedEstimatedPrice
      ? Number.parseInt(
          formattedEstimatedPrice.split(' - ').pop().split('CHF ').pop()
        )
      : 0;
    const notes = widget.querySelector('#notes').value;
    const domain = new URLSearchParams(window.location.search).get('domain')
      ? new URLSearchParams(window.location.search).get('domain')
      : window.location.origin;
    const referrer = new URLSearchParams(window.location.search).get('ref')
      ? new URLSearchParams(window.location.search).get('ref')
      : '';

    const data = {
      widget_key,
      token: state.phoneVerification.token,
      type: state.flow,
      sell_option: sellOption,
      vehicle: {
        make,
        model,
        first_registration_year: year,
        first_registration_month: month,
        body: bodyType,
        fuel,
        gear,
        exact_model: type,
        article_key: vehicleId,
        mileage,
        condition,
        equipment,
        when_to_sell: whenToSell,
        notes,
      },
      contact: {
        first_name: firstName,
        last_name: lastName,
        email,
        phone,
        language: state.lang,
      },
      price_from: priceFrom,
      price_to: priceTo,
      widget: 'default',
      domain,
      referrer,
    };

    if (state.flow === 'lead') {
      delete data.contact.phone;
      delete data.price_from;
      delete data.price_to;
    }

    state.isLoading = true;

    postSubmission(data)
      .then(({ submission_id }) => {
        if (!submission_id) {
          throw new Error('No submission_id');
        }

        handleGoNext(19);
        sendAnalytics('widget_success_message');
        state.isLoading = false;

        //document.removeEventListener('mousemove', mouseEvent);
        window.parent.postMessage({ type: 'success' }, '*');

        let domain = '';

        if (
          widgetContent.brand &&
          widgetContent.brand.customized_upload_area_domain
        ) {
          domain = widgetContent.brand.customized_upload_area_domain;

          if (domain.slice(-1) === '/') {
            domain = domain.slice(0, -1);
          }
        } else {
          domain = 'https://upload.carauktion-direct.ch';
        }

        redirectUrl = `${domain}/u/${submission_id}?id=${widget_key}&lang=${state.lang}`;
      })
      .catch((err) => {
        if (!isSecondTry && (!err.response || err.response.status === 502)) {
          return onSubmit(true);
        }

        sendRequestFailedLog(err);
        alert(
          'Unfortunately something went wrong! Please try again later or contact us.'
        );
      });
  };

  const handleGoNext = (itemStep) => {
    if (state.currentStep > itemStep) {
      state.currentStep = itemStep;

      return setTimeout(() => {
        state.currentStep = itemStep + 1;
        scrollToBottom();
      }, 400);
    }

    state.currentStep = itemStep + 1;
    scrollToBottom();
  };

  const handleGoBack = () => {
    let newStep = state.currentStep !== 1 ? state.currentStep - 1 : 0;

    while (state.skippedSteps.indexOf(newStep) !== -1) {
      newStep--;
    }

    state.currentStep = newStep;

    if (state.currentStep < isShrinkedNavbar.triggerStep) {
      return (isShrinkedNavbar.value = false);
    }

    scrollToBottom();
  };

  const scrollToBottom = () => {
    setTimeout(() => {
      isShrinkedNavbar.isTriggeredScroll = true;
      widget.scrollTo({
        top: 9999,
        behavior: 'smooth',
      });
      setTimeout(() => {
        isShrinkedNavbar.isTriggeredScroll = false;
      }, 500);
    }, 50);
  };

  const onScroll = (e) => {
    if (isShrinkedNavbar.isTriggeredScroll && isShrinkedNavbar.value) return;

    const scrollTop = e.target.scrollTop;

    if (scrollTop >= 80) {
      if (!isShrinkedNavbar.triggerStep) {
        isShrinkedNavbar.triggerStep = state.currentStep;
      }
      isShrinkedNavbar.value = true;
    } else if (scrollTop <= 10) {
      isShrinkedNavbar.value = false;
    }
  };

  const getNavbarProgressStage = (currentStep) => {
    if (currentStep <= 8) {
      return 0;
    } else if (currentStep <= 12) {
      return 1;
    } else {
      return 2;
    }
  };

  const getContentString = (content, key) => {
    if (!content) {
      return;
    }

    return extractContentString(content, key, state.lang);
  };

  const getFormFieldString = (content, key, prop) => {
    if (!content) {
      return;
    }

    return extractFormFieldString(content, key, state.lang, prop);
  };

  const getFormFieldType = (content, key) => {
    if (!content) {
      return;
    }

    return extractFormFieldString(content, key, state.lang).type;
  };

  const sendResultLog = () => {
    const log = {
      filter_brand: MAKES[state.selectedFields.make],
      filter_model: state.selectedFields.model,
      filter_year: state.selectedFields.year,
      filter_body: BODY_TYPES[state.selectedFields.vehicleParams.bodyType],
      filter_fuel: FUEL_TYPES[state.selectedFields.vehicleParams.fuel],
      filter_gear: GEAR_TYPES[state.selectedFields.vehicleParams.gear],
      filter_exact_model: state.selectedFields.vehicleParams.type,
      result_list: formatRelevantVehicles(
        state.vehicles.all,
        state.entryType !== 'plate-number'
          ? state.selectedFields.vehicleParams
          : {},
        state.lang,
        attributeContent,
        widgetContent.general_text_strings
      ).map((el) => {
        el.article_key = el.value;
        el.hint = el.hint.replace(/\s+/g, ' ').replace(/<br\/> /g, '\n');
        delete el.value;
        return el;
      }),
    };

    if (plateNumber) {
      log.plateNumber = plateNumber;
    }

    sendResultListWithWebhook(log);
  };

  const extractMonth = (articleKey) => {
    const date = state.vehicles.all.find(
      (el) => el.articleKey === articleKey
    ).firstRegistrationDate;
    const firstRegistrationMonth = Number.parseInt(date.split('.')[1]) - 1;
    state.selectedFields.month = firstRegistrationMonth;
  };

  const mouseEvent = (e) => {
    if (e.clientY < 10) {
      onWidgetClose();
    }
  };

  const onWidgetClose = () => {
    window.parent.postMessage(
      {
        type: 'close',
        // payload: {
        //   lastStep: state.currentStepName,
        // },
        payload: {
          lastStep: 'widget_success_message',
        },
      },
      '*'
    );
  };

  const sendOtpCode = async (phone) => {
    state.phoneVerification.isLoading = true;
    try {
      await verifyPhoneNumber({ phone });
      state.phoneVerification.codeSent = true;
      state.phoneVerification.phone = phone;
      state.phoneVerification.isLoading = false;
      localStorage.setItem(LS_KEY_PHONE, state.phoneVerification.phone);
    } catch {
      alert('Something went wrong');
    }
  };

  const toggleEntryType = () => {
    state.models = [];
    state.vehicles = {
      all: [],
      bodyTypes: [],
      fuels: [],
      gears: [],
      types: [],
    };
    state.estimatedPrice = null;
    state.currentStep = 0;
    state.skippedSteps = [];
    state.currentStepName = '';
    state.entryType =
      state.entryType === 'plate-number' ? 'brand-model' : 'plate-number';

    state.selectedFields = {
      make: null,
      model: null,
      year: null,
      month: null,
      vehicleParams: {
        bodyType: null,
        fuel: null,
        gear: null,
        type: null,
      },
      vehicleId: null,
      mileage: null,
      condition: null,
      equipment: null,
      whenToSell: null,
      sellOption: SKIP_SALE_OPTIONS_STEP ? 'fixprice' : null,
      contacts: {
        email: null,
        firstName: null,
        lastName: null,
        phone: null,
      },
    };

    scrollToBottom();
  };
</script>

<div class="wrapper">
  <div
    bind:this={widget}
    on:scroll={onScroll}
    class="cadirect-widget"
    id="cadirect-widget"
    style={!state.isLoading && state.currentStep !== FINAL_STEP
      ? 'padding-bottom: 170px;'
      : ''}
  >
    {#if (state.isLoading && !_isMounted) || !widgetContent}
      <LoadingAnimation fullsize />
    {:else}
      <Modal
        isOpen={state.openedModals[0]}
        onClose={() => onModalClose(0)}
        title={getContentString(
          widgetContent.directsale_text_strings,
          'offer_option_fixprice_modal_title'
        )}
        buttonText={getContentString(
          widgetContent.directsale_text_strings,
          'offer_option_fixprice_modal_button'
        )}
      >
        {getContentString(
          widgetContent.directsale_text_strings,
          'offer_option_fixprice_modal_description'
        )}
      </Modal>
      <Modal
        isOpen={state.openedModals[1]}
        onClose={() => onModalClose(1)}
        title={getContentString(
          widgetContent.directsale_text_strings,
          'offer_option_auction_modal_title'
        )}
        buttonText={getContentString(
          widgetContent.directsale_text_strings,
          'offer_option_auction_modal_button'
        )}
      >
        {getContentString(
          widgetContent.directsale_text_strings,
          'offer_option_auction_modal_description'
        )}
      </Modal>
      <Modal
        isOpen={state.openedModals[2]}
        onClose={() => onModalClose(2)}
        title={getContentString(
          widgetContent.general_text_strings,
          'error_no_vehicle_modal_title'
        )}
        buttonText={getContentString(
          widgetContent.general_text_strings,
          'error_no_vehicle_modal_button'
        )}
      >
        {getContentString(
          widgetContent.general_text_strings,
          'error_no_vehicle_modal_description'
        )}
      </Modal>
      <Modal
        isOpen={state.openedModals[3]}
        onClose={() => onModalClose(3)}
        title={getContentString(
          widgetContent.general_text_strings,
          'error_no_residual_value_modal_title'
        )}
        buttonText={getContentString(
          widgetContent.general_text_strings,
          'error_no_residual_value_modal_button'
        )}
      >
        {getContentString(
          widgetContent.general_text_strings,
          'error_no_residual_value_modal_description'
        )}
      </Modal>
      <Modal
        isOpen={state.openedModals[4]}
        onClose={() => {
          onModalClose(4);
          toggleEntryType();
        }}
        title={getContentString(
          widgetContent.general_text_strings,
          'error_no_license_plate_number_modal_title'
        )}
        buttonText={getContentString(
          widgetContent.general_text_strings,
          'error_no_license_plate_number_modal_button'
        )}
      >
        {getContentString(
          widgetContent.general_text_strings,
          'error_no_license_plate_number_modal_description'
        )}
      </Modal>
      <Modal
        isOpen={state.openedModals[5]}
        title={getContentString(
          widgetContent.general_text_strings,
          '2fa_modal_title'
        )}
      >
        <p>
          {getContentString(
            widgetContent.general_text_strings,
            '2fa_modal_description'
          )}
        </p>
        {#if !state.phoneVerification.codeSent}
          <InputField
            className="mt-4 !px-0"
            label={getFormFieldString(
              widgetContent.form_fields,
              'phone',
              'label'
            )}
            hint={getFormFieldString(
              widgetContent.form_fields,
              'phone',
              'hint'
            )}
            placeholder={getFormFieldString(
              widgetContent.form_fields,
              'phone',
              'placeholder'
            )}
            isLoading={state.phoneVerification.isLoading}
            type={getFormFieldType(widgetContent.form_fields, 'phone')}
            buttonText={getContentString(
              widgetContent.general_text_strings,
              'input_text_send_button'
            )}
            onSubmit={sendOtpCode}
          />
        {:else}<InputField
            className="mt-4 !px-0"
            label={getFormFieldString(
              widgetContent.form_fields,
              'otp_code',
              'label'
            )}
            hint={getFormFieldString(
              widgetContent.form_fields,
              'otp_code',
              'hint'
            )}
            placeholder={getFormFieldString(
              widgetContent.form_fields,
              'otp_code',
              'placeholder'
            )}
            isLoading={state.phoneVerification.isLoading}
            type={getFormFieldType(widgetContent.form_fields, 'otp_code')}
            buttonText={getContentString(
              widgetContent.general_text_strings,
              'input_text_send_button'
            )}
            isInvalid={state.phoneVerification.invalidCode}
            errorMsg={getContentString(
              widgetContent.general_text_strings,
              '2fa_invalid_otp_code'
            )}
            onSubmit={async (code) => {
              state.phoneVerification.isLoading = true;
              try {
                const { token } = await verifyPhoneNumber({
                  phone: state.phoneVerification.phone,
                  code,
                });
                state.phoneVerification = {
                  ...INITAL_PHONE_VERIFICATION,
                  token,
                };

                // TODO: save token
                onModalClose(5);
                onGetEstimatedValue().then(() => handleGoNext(11));
              } catch {
                state.phoneVerification.isLoading = false;
                state.phoneVerification.invalidCode = true;
              }
            }}
          /><CodeRetry
            hint={getContentString(
              widgetContent.general_text_strings,
              '2fa_not_received_hint'
            )}
            resendLabel={getContentString(
              widgetContent.general_text_strings,
              '2fa_resend_in'
            )}
            btnText={getContentString(
              widgetContent.general_text_strings,
              '2fa_resend_btn'
            )}
            editPhoneNumberLabel={getContentString(
              widgetContent.general_text_strings,
              '2fa_edit_phone_number_btn'
            )}
            onEditPhoneNumber={() => {
              state.phoneVerification.codeSent = false;
              state.phoneVerification.isLoading = false;
            }}
            onResend={() =>
              verifyPhoneNumber({ phone: state.phoneVerification.phone }).catch(
                () => alert('Something went wrong')
              )}
          />{/if}
      </Modal>
      <Modal isLoadingAnimation isOpen={state.isLoading && _isMounted} />

      <Navbar
        stage={getNavbarProgressStage(state.currentStep)}
        logo={state.theme.logo}
        isShrinked={isShrinkedNavbar.value}
        showBackButton={!(
          state.currentStep === 0 || state.currentStep === FINAL_STEP
        )}
        onGoBack={handleGoBack}
        stageNames={formatNavbarContent(
          state.flow === 'lead'
            ? widgetContent.leadgen_text_strings
            : widgetContent.directsale_text_strings,
          state.lang
        )}
        onClose={onWidgetClose}
      />

      {#if state.currentStep < FINAL_STEP}
        <Fade show={state.currentStep < FINAL_STEP}>
          <Headline className="mt-12">
            {getContentString(
              state.flow === 'lead'
                ? widgetContent.leadgen_text_strings
                : widgetContent.directsale_text_strings,
              'search_title'
            )}
          </Headline>
          <ButtonSwitch
            label={getContentString(
              widgetContent.general_text_strings,
              'search_type_label'
            )}
            options={[
              {
                name: getContentString(
                  widgetContent.general_text_strings,
                  'search_type_option_license_plate_number'
                ),
                onSelect: toggleEntryType,
                isActive: state.entryType === 'plate-number',
              },
              {
                name: getContentString(
                  widgetContent.general_text_strings,
                  'search_type_option_brand_model'
                ),
                onSelect: toggleEntryType,
                isActive: state.entryType !== 'plate-number',
              },
            ]}
          />

          {#if state.entryType === 'plate-number'}
            <InputField
              label={getFormFieldString(
                widgetContent.form_fields,
                'license_plate_number',
                'label'
              )}
              hint={getFormFieldString(
                widgetContent.form_fields,
                'license_plate_number',
                'hint'
              )}
              placeholder={getFormFieldString(
                widgetContent.form_fields,
                'license_plate_number',
                'placeholder'
              )}
              buttonText={getContentString(
                widgetContent.general_text_strings,
                'input_text_send_button'
              )}
              onSubmit={(value) => {
                plateNumber = value.replace(/[^a-z0-9]/gi, '');
                onGetVehicleByLicensePlate();

                //window.parent.postMessage({ type: 'first-interaction' }, '*');
                //document.addEventListener('mousemove', mouseEvent);

                sendAnalytics('widget_plate_number');
              }}
            />
          {:else}
            <SelectField
              label={getFormFieldString(
                widgetContent.form_fields,
                'make',
                'label'
              )}
              hint={getFormFieldString(
                widgetContent.form_fields,
                'make',
                'hint'
              )}
              placeholder={getFormFieldString(
                widgetContent.form_fields,
                'make',
                'placeholder'
              )}
              options={state.makes}
              onSelect={(value) => {
                state.selectedFields.make = value;
                onGetAllModels();
                handleGoNext(0);

                //window.parent.postMessage({ type: 'first-interaction' }, '*');
                //document.addEventListener('mousemove', mouseEvent);

                sendAnalytics('widget_make');
              }}
            />
          {/if}
        </Fade>

        {#if state.entryType !== 'plate-number'}
          <Fade show={state.currentStep >= 1}>
            <SelectField
              label={getFormFieldString(
                widgetContent.form_fields,
                'model',
                'label'
              )}
              hint={getFormFieldString(
                widgetContent.form_fields,
                'model',
                'hint'
              )}
              placeholder={getFormFieldString(
                widgetContent.form_fields,
                'model',
                'placeholder'
              )}
              options={state.models}
              onSelect={(value) => {
                state.selectedFields.model = value;
                handleGoNext(1);

                sendAnalytics('widget_model');
              }}
            />
          </Fade>

          <Fade show={state.currentStep >= 2}>
            <SelectField
              label={getFormFieldString(
                widgetContent.form_fields,
                'first_registration_year',
                'label'
              )}
              hint={getFormFieldString(
                widgetContent.form_fields,
                'first_registration_year',
                'hint'
              )}
              placeholder={getFormFieldString(
                widgetContent.form_fields,
                'first_registration_year',
                'placeholder'
              )}
              options={formatYears()}
              onSelect={(value) => {
                state.selectedFields.year = value;
                onGetVehicles().then(() => handleGoNext(3));

                sendAnalytics('widget_first_reg_year');
              }}
            />
          </Fade>

          <!-- <Fade show={state.currentStep >= 3}>
          <SelectField
            label={getFormFieldString(
              widgetContent.form_fields,
              'first_registration_month',
              'label'
            )}
            hint={getFormFieldString(
              widgetContent.form_fields,
              'first_registration_month',
              'hint'
            )}
            placeholder={getFormFieldString(
              widgetContent.form_fields,
              'first_registration_month',
              'placeholder'
            )}
            options={formatMonths()}
            onSelect={(value) => {
              state.selectedFields.month = value;
              onGetVehicles();
              handleGoNext(3);
            }}
          />
        </Fade> -->

          <Fade
            show={state.currentStep >= 4 && state.vehicles.bodyTypes.length > 1}
          >
            <SelectField
              label={getFormFieldString(
                widgetContent.form_fields,
                'body',
                'label'
              )}
              hint={getFormFieldString(
                widgetContent.form_fields,
                'body',
                'hint'
              )}
              placeholder={getFormFieldString(
                widgetContent.form_fields,
                'body',
                'placeholder'
              )}
              options={getAttributeNamesByKey(
                'bodyType',
                state.vehicles.bodyTypes,
                state.lang,
                attributeContent
              )}
              onSelect={(value) => {
                state.selectedFields.vehicleParams.bodyType = value;
                handleGoNext(4);

                sendAnalytics('widget_body');
              }}
            />
          </Fade>

          <Fade
            show={state.currentStep >= 5 && state.vehicles.fuels.length > 1}
          >
            <SelectField
              label={getFormFieldString(
                widgetContent.form_fields,
                'fuel',
                'label'
              )}
              hint={getFormFieldString(
                widgetContent.form_fields,
                'fuel',
                'hint'
              )}
              placeholder={getFormFieldString(
                widgetContent.form_fields,
                'fuel',
                'placeholder'
              )}
              options={getAttributeNamesByKey(
                'fuel',
                getRelevantOptions(
                  'fuel',
                  state.vehicles.all,
                  state.selectedFields.vehicleParams
                ),
                state.lang,
                attributeContent
              )}
              onSelect={(value) => {
                state.selectedFields.vehicleParams.fuel = value;
                handleGoNext(5);

                sendAnalytics('widget_fuel');
              }}
            />
          </Fade>

          <Fade
            show={state.currentStep >= 6 && state.vehicles.gears.length > 1}
          >
            <SelectField
              label={getFormFieldString(
                widgetContent.form_fields,
                'gear',
                'label'
              )}
              hint={getFormFieldString(
                widgetContent.form_fields,
                'gear',
                'hint'
              )}
              placeholder={getFormFieldString(
                widgetContent.form_fields,
                'gear',
                'placeholder'
              )}
              options={getAttributeNamesByKey(
                'gear',
                getRelevantOptions(
                  'gear',
                  state.vehicles.all,
                  state.selectedFields.vehicleParams
                ),
                state.lang,
                attributeContent
              )}
              onSelect={(value) => {
                state.selectedFields.vehicleParams.gear = value;
                handleGoNext(6);

                sendAnalytics('widget_gear');
              }}
            />
          </Fade>

          <Fade show={state.currentStep >= 7}>
            <SelectField
              label={getFormFieldString(
                widgetContent.form_fields,
                'exact_model',
                'label'
              )}
              hint={getFormFieldString(
                widgetContent.form_fields,
                'exact_model',
                'hint'
              )}
              placeholder={getFormFieldString(
                widgetContent.form_fields,
                'exact_model',
                'placeholder'
              )}
              options={getRelevantOptions(
                'type',
                state.vehicles.all,
                state.selectedFields.vehicleParams
              )}
              onSelect={(value) => {
                state.selectedFields.vehicleParams.type = value;

                sendResultLog();

                // Skip the next step if there is only one relevant vehicle
                const relevantVehicles = formatRelevantVehicles(
                  state.vehicles.all,
                  state.selectedFields.vehicleParams
                );

                sendAnalytics('widget_exact_model');

                if (relevantVehicles.length === 1) {
                  extractMonth(relevantVehicles[0].value);
                  state.selectedFields.vehicleId = relevantVehicles[0].value;
                  return handleGoNext(8);
                }

                handleGoNext(7);
              }}
            />
          </Fade>
        {/if}

        <Fade show={state.currentStep >= 8}>
          <ResultPanel
            options={formatRelevantVehicles(
              state.vehicles.all,
              state.entryType !== 'plate-number'
                ? state.selectedFields.vehicleParams
                : {},
              state.lang,
              attributeContent,
              widgetContent.general_text_strings
            )}
            name="relevant-vehicles"
            onSelect={(value) => {
              if (state.entryType === 'plate-number') {
                parseSelectedFields(
                  state.vehicles.all.find((el) => el.articleKey === value)
                );
              }

              extractMonth(value);
              state.selectedFields.vehicleId = value;
              handleGoNext(8);

              sendAnalytics('widget_choose_exact_model_type');
            }}
          />
        </Fade>

        <Fade show={state.currentStep >= 9}>
          <Headline className="mt-12">
            {getContentString(
              state.flow === 'lead'
                ? widgetContent.leadgen_text_strings
                : widgetContent.directsale_text_strings,
              'condition_title'
            )}
          </Headline>
          <InputField
            label={getFormFieldString(
              widgetContent.form_fields,
              'mileage',
              'label'
            )}
            hint={getFormFieldString(
              widgetContent.form_fields,
              'mileage',
              'hint'
            )}
            placeholder={getFormFieldString(
              widgetContent.form_fields,
              'mileage',
              'placeholder'
            )}
            type={getFormFieldType(widgetContent.form_fields, 'mileage')}
            buttonText={getContentString(
              widgetContent.general_text_strings,
              'input_text_send_button'
            )}
            onSubmit={(value) => {
              state.selectedFields.mileage = value;
              handleGoNext(9);

              sendAnalytics('widget_mileage');
            }}
          />
        </Fade>

        <Fade show={state.currentStep >= 10}>
          <SelectField
            label={getFormFieldString(
              widgetContent.form_fields,
              'condition',
              'label'
            )}
            hint={getFormFieldString(
              widgetContent.form_fields,
              'condition',
              'hint'
            )}
            placeholder={getFormFieldString(
              widgetContent.form_fields,
              'condition',
              'placeholder'
            )}
            options={formatGeneralOptions(
              widgetContent.general_text_strings,
              'condition_options_',
              state.lang
            )}
            onSelect={(value) => {
              state.selectedFields.condition = value;
              handleGoNext(10);

              sendAnalytics('widget_condition');
            }}
          />
        </Fade>

        <Fade show={state.currentStep >= 11}>
          <SelectField
            label={getFormFieldString(
              widgetContent.form_fields,
              'equipment',
              'label'
            )}
            hint={getFormFieldString(
              widgetContent.form_fields,
              'equipment',
              'hint'
            )}
            placeholder={getFormFieldString(
              widgetContent.form_fields,
              'equipment',
              'placeholder'
            )}
            options={formatGeneralOptions(
              widgetContent.general_text_strings,
              'equipment_options_',
              state.lang
            )}
            onSelect={async (value) => {
              state.selectedFields.equipment = value;

              if (state.flow === 'lead') {
                handleGoNext(11);
              } else {
                if (widgetContent.enable_2fa) {
                  if (state.phoneVerification.phone) {
                    state.isLoading = true;
                    try {
                      await sendOtpCode(state.phoneVerification.phone);
                      state.isLoading = false;
                    } catch {}
                  }
                  onModalOpen(5);
                } else {
                  onGetEstimatedValue().then(() => handleGoNext(11));
                }
              }

              sendAnalytics('widget_equipment');
            }}
          />
        </Fade>

        {#if state.flow === 'lead'}
          <Fade show={state.currentStep >= 12}>
            <Panel
              header={getContentString(
                widgetContent.leadgen_text_strings,
                'resultbox_label'
              )}
              description={getContentString(
                widgetContent.leadgen_text_strings,
                'resultbox_hint'
              )}
              buttonText={getContentString(
                widgetContent.leadgen_text_strings,
                'resultbox_button'
              )}
              onButtonClick={() => {
                handleGoNext(13);

                sendAnalytics('widget_send_offer');
              }}
            />
          </Fade>
        {:else}
          <Fade show={state.currentStep >= 12 && state.estimatedPrice}>
            <Panel
              header={getContentString(
                widgetContent.directsale_text_strings,
                'resultbox_label'
              )}
              price={state.estimatedPrice}
              description={getContentString(
                widgetContent.directsale_text_strings,
                'resultbox_hint'
              )}
              buttonText={getContentString(
                widgetContent.directsale_text_strings,
                'resultbox_button'
              )}
              onButtonClick={() => {
                handleGoNext(SKIP_SALE_OPTIONS_STEP ? 13 : 12);

                sendAnalytics('widget_send_offer');
              }}
            />
          </Fade>

          <Fade show={state.currentStep >= 13 && !SKIP_SALE_OPTIONS_STEP}>
            <Headline className="mt-12">
              {getContentString(
                widgetContent.directsale_text_strings,
                'offer_title'
              )}
            </Headline>
            <ResultPanel
              name="selling-options"
              options={formatSellOptions(
                widgetContent.directsale_text_strings,
                state.lang
              )}
              onSelect={(value) => {
                state.selectedFields.sellOption = value;
                handleGoNext(13);
              }}
              openModal={(modalIndex) => onModalOpen(modalIndex)}
            />
          </Fade>
        {/if}

        <Fade show={state.currentStep >= 14}>
          <Headline>
            {state.flow === 'lead'
              ? getContentString(
                  widgetContent.leadgen_text_strings,
                  'lead_form_title'
                )
              : getContentString(
                  widgetContent.directsale_text_strings,
                  'offer_form_title'
                )}
          </Headline>
          <InputField
            label={getFormFieldString(
              widgetContent.form_fields,
              'email',
              'label'
            )}
            hint={getFormFieldString(
              widgetContent.form_fields,
              'email',
              'hint'
            )}
            placeholder={getFormFieldString(
              widgetContent.form_fields,
              'email',
              'placeholder'
            )}
            type={getFormFieldType(widgetContent.form_fields, 'email')}
            buttonText={getContentString(
              widgetContent.general_text_strings,
              'input_text_send_button'
            )}
            onSubmit={(value) => {
              state.selectedFields.contacts.email = value;
              handleGoNext(14);

              sendAnalytics('widget_email');
            }}
          />
        </Fade>

        <Fade show={state.currentStep >= 15}>
          <InputField
            label={getFormFieldString(
              widgetContent.form_fields,
              'first_name',
              'label'
            )}
            hint={getFormFieldString(
              widgetContent.form_fields,
              'first_name',
              'hint'
            )}
            placeholder={getFormFieldString(
              widgetContent.form_fields,
              'first_name',
              'placeholder'
            )}
            type={getFormFieldType(widgetContent.form_fields, 'first_name')}
            buttonText={getContentString(
              widgetContent.general_text_strings,
              'input_text_send_button'
            )}
            onSubmit={(value) => {
              state.selectedFields.contacts.firstName = value;
              handleGoNext(15);

              sendAnalytics('widget_first_name');
            }}
          />
        </Fade>

        <Fade show={state.currentStep >= 16}>
          <InputField
            label={getFormFieldString(
              widgetContent.form_fields,
              'last_name',
              'label'
            )}
            hint={getFormFieldString(
              widgetContent.form_fields,
              'last_name',
              'hint'
            )}
            placeholder={getFormFieldString(
              widgetContent.form_fields,
              'last_name',
              'placeholder'
            )}
            type={getFormFieldType(widgetContent.form_fields, 'last_name')}
            buttonText={getContentString(
              widgetContent.general_text_strings,
              'input_text_send_button'
            )}
            onSubmit={(value) => {
              state.selectedFields.contacts.lastName = value;
              //handleGoNext(state.flow === 'lead' ? 16 : 15);
              handleGoNext(16);

              sendAnalytics('widget_last_name');
            }}
          />
        </Fade>

        <Fade show={state.currentStep >= 17}>
          <SelectField
            label={getFormFieldString(
              widgetContent.form_fields,
              'when_to_sell',
              'label'
            )}
            hint={getFormFieldString(
              widgetContent.form_fields,
              'when_to_sell',
              'hint'
            )}
            placeholder={getFormFieldString(
              widgetContent.form_fields,
              'when_to_sell',
              'placeholder'
            )}
            options={formatGeneralOptions(
              widgetContent.general_text_strings,
              'when_to_sell_options_',
              state.lang
            )}
            onSelect={(value) => {
              state.selectedFields.whenToSell = value;

              handleGoNext(17);

              sendAnalytics('widget_when_to_sell');
            }}
          />
        </Fade>

        <Fade show={state.currentStep >= 18 && state.flow !== 'lead'}>
          <InputField
            label={getFormFieldString(
              widgetContent.form_fields,
              'phone',
              'label'
            )}
            hint={getFormFieldString(
              widgetContent.form_fields,
              'phone',
              'hint'
            )}
            placeholder={getFormFieldString(
              widgetContent.form_fields,
              'phone',
              'placeholder'
            )}
            type={getFormFieldType(widgetContent.form_fields, 'phone')}
            buttonText={getContentString(
              widgetContent.general_text_strings,
              'input_text_send_button'
            )}
            onSubmit={(value) => {
              state.selectedFields.contacts.phone = value;
              handleGoNext(18);
            }}
          />
        </Fade>

        <Fade show={state.currentStep >= 19}>
          <TextAreaField
            id="notes"
            label={getFormFieldString(
              widgetContent.form_fields,
              'notes',
              'label'
            )}
            hint={getFormFieldString(
              widgetContent.form_fields,
              'notes',
              'hint'
            )}
            placeholder={getFormFieldString(
              widgetContent.form_fields,
              'notes',
              'placeholder'
            )}
          />
          <Button
            onClick={() => {
              onSubmit();
              sendAnalytics('widget_submission');
            }}
          >
            {state.flow === 'lead'
              ? getContentString(
                  widgetContent.leadgen_text_strings,
                  'lead_form_button'
                )
              : getContentString(
                  widgetContent.directsale_text_strings,
                  'offer_form_button'
                )}
          </Button>
        </Fade>
      {:else}
        <div class="flex h-full w-full flex-col items-center justify-center">
          <Fade show={state.currentStep >= FINAL_STEP}>
            <Headline>
              {getContentString(
                state.flow === 'lead'
                  ? widgetContent.leadgen_text_strings
                  : widgetContent.directsale_text_strings,
                'success_title'
              )}
            </Headline>
            <BodyText>
              {getContentString(
                state.flow === 'lead'
                  ? widgetContent.leadgen_text_strings
                  : widgetContent.directsale_text_strings,
                'success_description'
              )}
            </BodyText>
            <div class="mt-14">
              <Button href={redirectUrl} onClick={onWidgetClose}>
                {getContentString(
                  state.flow === 'lead'
                    ? widgetContent.leadgen_text_strings
                    : widgetContent.directsale_text_strings,
                  'success_button'
                )}
              </Button>
            </div>
            {#if isBrandedVersion}
              <div class="mt-14 flex flex-col items-center text-xs">
                <div class="mb-3">Powered by</div>
                <img class="w-32" src="/assets/logo_cad.svg" alt="" />
              </div>
            {/if}
          </Fade>
        </div>
      {/if}
    {/if}
  </div>
</div>

<style>
  .cadirect-widget {
    @apply relative h-screen text-center bg-white;
    width: 480px;
    color: var(--secondary);
    min-height: 420px;
    overflow-y: scroll;
  }

  @media screen and (max-width: 480px) {
    .cadirect-widget {
      width: auto;
    }
  }
</style>
