<script>
  import validator from 'validator';
  import ButtonLoading from './ButtonLoading.svelte';
  import cx from 'classnames';

  export let label;
  export let type = 'text';
  export let placeholder = 'Bitte eintragen...';
  export let buttonText = 'Weiter';
  export let onSubmit;
  export let isInvalid = false;
  export let errorMsg = '';
  export let isLoading = false;
  export let hint = '';
  export let className = '';

  let input;
  let submitBtn;

  const onFocus = () => {
    if (!onSubmit) return;

    input.addEventListener('keydown', keyDownEventHandler);
  };
  const onBlur = () =>
    input.removeEventListener('keydown', keyDownEventHandler);

  const keyDownEventHandler = (e) => {
    if (e.keyCode !== 13) return;
    // if ENTER is pressed
    submitBtn.click();
  };

  const focusOnNextInputField = () => {
    const nextInputField = Array.from(
      document.querySelectorAll('.InputField__input-block__input')
    ).pop();

    if (input !== nextInputField) {
      nextInputField.focus();
    }
  };

  const validateInputData = (value) => {
    if (!value) {
      return false;
    }

    if (type === 'email' && !validator.isEmail(value)) {
      isInvalid = true;
      return false;
    }

    if (
      type === 'tel' &&
      !validator.isMobilePhone(value, 'any', { strictMode: true })
    ) {
      isInvalid = true;
      return false;
    }

    isInvalid = false;
    return true;
  };
</script>

<div class={cx('InputField', className)}>
  {#if label}
    <h3 class="InputField__label">{label}</h3>
  {/if}
  <div class="InputField__input-block">
    <input
      bind:this={input}
      class="InputField__input-block__input"
      on:focus={onFocus}
      on:blur={onBlur}
      {placeholder}
      {type}
      class:invalid={isInvalid}
    />
    {#if onSubmit}
      <button
        bind:this={submitBtn}
        class="InputField__input-block__button"
        class:InputField__input-block__button__icon={!isLoading}
        on:click={() => {
          if (!validateInputData(input.value)) return;

          onSubmit(input.value);

          setTimeout(() => {
            // Fade duration time
            focusOnNextInputField();
          }, 300);
        }}
      >
        {buttonText}
        {#if isLoading}<ButtonLoading />{/if}
      </button>
    {/if}
  </div>
  {#if hint}
    <p class="InputField__hint">{hint}</p>
  {/if}
  {#if isInvalid && errorMsg}
    <p class="InputField__error">{errorMsg}</p>
  {/if}
</div>

<style>
  .InputField {
    @apply mb-6 px-6 text-sm text-left;
  }
  .InputField__label {
    @apply font-semibold;
  }
  .InputField__input-block {
    @apply relative;
  }
  .InputField__input-block__input {
    @apply my-1.5 p-3.5 w-full border rounded-lg shadow outline-none;
    border-color: var(--light-2);
    transition: all 0.45s ease-in;
  }
  .InputField__input-block__input::placeholder {
    color: var(--light-1);
  }
  .InputField__input-block__button {
    @apply absolute top-1/2 right-3.5 px-3 py-1 flex items-center transform -translate-x-0.5 -translate-y-1/2 text-white rounded-md outline-none;
    background-color: var(--primary);
  }
  .InputField__input-block__button__icon:after {
    content: '';
    @apply ml-0.5 w-4 h-4;
    background: url('/assets/icons/right-arrow.svg') center center no-repeat;
  }
  .InputField__hint {
    color: var(--light-1);
  }
  .InputField__error {
    color: var(--primary);
  }
  .invalid {
    border-color: var(--primary);
  }
</style>
