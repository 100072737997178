export const SUPPORTED_LANGUAGES = ['de', 'en', 'fr', 'it'];
export const FINAL_STEP = 20;
export const FLOW_LEAD_TYPE = 'lead';
export const FLOW_SELL_TYPE = 'sell';
export const PRODUCTION_ENVIRONMENT = 'production';
export const LS_KEY_PHONE = 'cadPhoneNumber';

export const DEFAULT_THEME = {
  primary: '#C20E1A',
  secondary: '#333333',
  logo: 'https://storage.googleapis.com/cad_widget_media/logo_cad_f81ca71466/logo_cad_f81ca71466.svg',
};

export const MAKES = {
  120: 'BMW',
  125: 'BMW Alpina',
  140: 'Buick',
  160: 'Cadillac',
  165: 'Caterham',
  180: 'Chevrolet',
  200: 'Chrysler',
  201: 'Chrysler USA',
  220: 'Citroën',
  227: 'CUPRA',
  230: 'Dacia',
  231: 'Daewoo',
  240: 'Daihatsu',
  241: 'Daimler',
  242: 'Dallas',
  243: 'De Tomaso',
  246: 'Dodge',
  249: 'Dodge USA',
  252: 'DS Automobiles',
  255: 'Ferrari',
  260: 'Fiat',
  263: 'Fisker',
  280: 'Ford',
  300: 'Ford USA',
  320: 'Honda',
  336: 'Hummer',
  340: 'Hyundai',
  350: 'Infiniti',
  360: 'Innocenti',
  380: 'Isuzu',
  410: 'JAC',
  420: 'Jaguar',
  440: 'Jeep',
  451: 'Kia',
  460: 'Lada',
  470: 'Lamborghini',
  480: 'Lancia',
  482: 'Land Rover',
  500: 'Lexus',
  510: 'Lotus',
  520: 'Maserati',
  540: 'Mazda',
  558: 'Mega',
  560: 'Mercedes-Benz',
  570: 'MG',
  615: 'Mini',
  620: 'Mitsubishi',
  625: 'Morgan',
  640: 'Nissan',
  660: 'Oldsmobile',
  680: 'Opel',
  700: 'Peugeot',
  716: 'Polestar',
  720: 'Pontiac',
  740: 'Porsche',
  760: 'Puch',
  780: 'Renault',
  790: 'Rolls Royce',
  800: 'Rover',
  820: 'Saab',
  822: 'Saab-Hirsch',
  840: 'SEAT',
  860: 'Skoda',
  862: 'Smart',
  864: 'SsangYong',
  880: 'Subaru',
  881: 'Subaru USA',
  900: 'Suzuki',
  920: 'Talbot',
  924: 'Tata',
  930: 'Tesla',
  940: 'Toyota',
  947: 'TVR',
  960: 'Volvo',
  980: 'VW',
  982: 'Westfield',
  '010': 'Aixam',
  '020': 'Alfa Romeo',
  '022': 'Alpine',
  '040': 'AMC',
  '055': 'Aston Martin',
  '060': 'Audi',
  '080': 'Austin',
  '090': 'Bentley',
};
export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
export const BODY_TYPES = {
  1: 'Geländewagen / SUV',
  2: 'Coupé',
  3: 'Grossraum / Van',
  4: 'Kombi',
  5: 'Limousine',
  6: 'Sportwagen',
  7: 'Cabriolet',
  8: 'Kastenwagen',
  9: 'Lieferwagen',
  10: 'Pick-Up',
  11: 'Bus',
};
export const FUEL_TYPES = {
  1: 'Benzin',
  2: 'Benzin Kat',
  3: 'Diesel',
  4: 'Diesel Partikelfilter',
  5: 'Gas / Benzin',
  6: 'Ethanol / Benzin',
  7: 'Elektro / Benzin',
  8: 'Elektro / Diesel',
  9: 'Elektro',
  10: 'Wasserstoff',
  11: 'El./Benzin Mild-Hybrid',
  12: 'El./Benzin Plug-in-Hybrid',
  13: 'El./Benzin Voll-Hybrid',
  14: 'El./Diesel Mild-Hybrid',
  15: 'El./Diesel Plug-in-Hybrid',
  16: 'Mild-Hybrid/Benzin',
  17: 'Plug-in-Hybrid/Benzin',
  18: 'Voll-Hybrid/Benzin',
  19: 'Mild-Hybrid/Diesel',
  20: 'Plug-in-Hybrid/Diesel',
  21: 'Voll-Hybrid/Diesel',
};
export const GEAR_TYPES = {
  1: 'Manuell',
  2: 'Mech. Automatik',
  3: 'Automatik',
  4: 'Stufenlos',
  5: 'Automatik',
};
export const CONDITION_TYPES = {
  1: 'Sehr schlechter Zustand',
  2: 'Schlechter Zustand',
  3: 'Guter Zustand',
  4: 'Sehr guter Zustand',
};
export const EQUIPMENT_TYPES = {
  1: 'Nur Serienausstattung',
  2: 'Normaler Ausstattungsgrad',
  3: 'Hoher Ausstattungsgrad',
  4: 'Sehr hoher Ausstattungsgrad',
};
