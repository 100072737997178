<script>
  export let stage = 0;
  export let small = false;
  export let stageNames;

  const getStatus = (stage, stageIndex) => {
    if (stageIndex < stage) {
      return 'done';
    }
    if (stageIndex === stage) {
      return 'active';
    }
    return 'next';
  };
</script>

<style>
  .ProgressBar {
    @apply relative flex justify-between items-center px-10;
  }
  .ProgressBar__connector-line {
    @apply absolute top-1/2 left-1/2 z-0 transform -translate-x-2/4;
    width: calc(100% - 80px - 2rem);
    height: 1px;
    background-color: var(--light-2);
  }
  .ProgressBar__step {
    @apply relative flex flex-col w-12 h-12 items-center justify-center;
    transition: all 0.4s ease;
  }
  .ProgressBar__step__icon {
    @apply relative flex flex-col items-center justify-center w-6 h-6 rounded-full;
    background-color: var(--light-2);
    transition: all 0.4s ease;
  }
  .ProgressBar__step__icon img {
    width: 0;
    transition: all 0.4s ease;
  }
  .ProgressBar--small .ProgressBar__step {
    @apply w-auto h-auto;
  }
  .ProgressBar--small .active .ProgressBar__step__icon img,
  .ProgressBar--small .done .ProgressBar__step__icon img {
    @apply hidden;
  }
  .ProgressBar__step__title {
    @apply absolute my-1 left-1/2 transform -translate-x-2/4 font-semibold text-xs;
    bottom: -24px;
    color: var(--light-2);
    transition: all 0.4s ease;
  }
  .active .ProgressBar__step__icon,
  .done .ProgressBar__step__icon {
    @apply relative flex justify-center items-center w-12 h-12 rounded-full z-10;
  }
  .ProgressBar--small .active .ProgressBar__step__icon,
  .ProgressBar--small .done .ProgressBar__step__icon {
    @apply w-6 h-6;
  }
  .active .ProgressBar__step__title {
    color: var(--primary);
  }
  .ProgressBar--small .active .ProgressBar__step__title {
    color: var(--secondary);
  }
  .active .ProgressBar__step__icon {
    background-color: var(--primary);
  }
  .done .ProgressBar__step__icon {
    color: var(--light-2);
  }
  .active .ProgressBar__step__icon img,
  .done .ProgressBar__step__icon img {
    @apply w-5;
  }
  @media (max-width: 480px) {
    .ProgressBar {
      @apply px-0;
    }
    .ProgressBar--small {
      @apply px-10;
    }
    .ProgressBar__connector-line {
      width: calc(100% - 2rem);
    }
    .ProgressBar--small .ProgressBar__connector-line {
      width: calc(100% - 80px);
    }
  }
</style>

<div class="ProgressBar {small ? 'ProgressBar--small' : ''}">
  <div class="ProgressBar__connector-line" />
  <div class="ProgressBar__step {getStatus(stage, 0)}">
    <div class="ProgressBar__step__icon">
      <img src="assets/progress-bar/suche.svg" alt="Step icon" />
    </div>
    <div class="ProgressBar__step__title">{stageNames[0]}</div>
  </div>
  <div class="ProgressBar__step {getStatus(stage, 1)}">
    <div class="ProgressBar__step__icon">
      <img src="assets/progress-bar/zustand.svg" alt="Step icon" />
    </div>
    <div class="ProgressBar__step__title">{stageNames[1]}</div>
  </div>
  <div class="ProgressBar__step {getStatus(stage, 2)}">
    <div class="ProgressBar__step__icon">
      <img src="assets/progress-bar/suche.svg" alt="Step icon" />
    </div>
    <div class="ProgressBar__step__title">{stageNames[2]}</div>
  </div>
</div>
