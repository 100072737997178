<script>
  import ProgressBar from './ProgressBar.svelte';

  export let logo;
  export let stage;
  export let isShrinked = false;
  export let showBackButton;
  export let onGoBack;
  export let onClose;
  export let stageNames = ['Suche', 'Zustand', 'Angebot'];
</script>

<header class="Navbar {isShrinked ? 'Navbar--shrinked' : ''} fadeIn">
  {#if showBackButton}
    <div class="Navbar__back-btn" on:click={onGoBack} />
  {/if}
  <div class="Navbar__close-btn" on:click={onClose} />
  <img class="Navbar__logo" src={logo} alt="Logo" />
  <ProgressBar {stage} {stageNames} small={isShrinked} />
</header>

<style>
  .Navbar {
    @apply relative p-6 pb-5;
  }
  .Navbar__logo {
    @apply mx-auto mb-6 select-none;
    max-width: 120px;
    height: 52px;
  }
  .Navbar__back-btn,
  .Navbar__close-btn {
    @apply absolute w-6 h-6 cursor-pointer z-10;
    top: 40px;
    background-size: 100%;
    transition: all 0.4s ease;
  }
  .Navbar__back-btn {
    left: 24px;
    background-image: url('/assets/icons/back.svg');
  }
  .Navbar__close-btn {
    right: 24px;
    background-image: url('/assets/icons/close.svg');
  }
  .Navbar--shrinked {
    @apply fixed p-6 pb-10 bg-white shadow-lg z-40;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    width: inherit;
  }
  .Navbar--shrinked .Navbar__logo {
    @apply hidden;
  }
  .Navbar--shrinked .Navbar__back-btn,
  .Navbar--shrinked .Navbar__close-btn {
    top: 30px;
  }
  @media (max-width: 480px) {
    .Navbar--shrinked {
      @apply w-full;
    }
  }
</style>
