<script>
  export let onClick;
  export let href;
</script>

{#if href}
  <a
    {href}
    target="_blank"
    class="Button"
    on:click={() => setTimeout(onClick, 100)}
  >
    <slot />
  </a>
{:else}
  <button class="Button" on:click={() => onClick()}>
    <slot />
  </button>
{/if}

<style>
  .Button {
    @apply block my-6 mx-6 p-2.5 w-full text-xl font-bold text-white rounded-lg outline-none sm:text-2xl;
    width: calc(100% - 48px);
    background-color: var(--primary);
  }
</style>
