/* --- CONTENT --- */

export const extractContentString = (content, key, lang) =>
  content.find((el) => el.key === key)[lang];
export const extractFormFieldString = (content, key, lang, prop) => {
  const fieldObject = content.find((el) => el.key === key);

  if (!prop) {
    return fieldObject;
  }

  return fieldObject.translation.find((el) => el.language === lang)
    ? fieldObject.translation.find((el) => el.language === lang)[prop]
    : null;
};
export const formatNavbarContent = (directsale_text_strings, lang) => [
  directsale_text_strings.find((el) => el.key === 'step_search_title')[lang],
  directsale_text_strings.find((el) => el.key === 'step_condition_title')[lang],
  directsale_text_strings.find((el) => el.key === 'step_offer_title')[lang],
];
export const formatSellOptions = (directsale_text_strings, lang) => [
  {
    value: 'fixprice',
    title: directsale_text_strings.find(
      (el) => el.key === 'offer_option_fixprice_title'
    )[lang],
    badge: directsale_text_strings.find(
      (el) => el.key === 'offer_option_fixprice_badge'
    )[lang],
    hint: directsale_text_strings.find(
      (el) => el.key === 'offer_option_fixprice_hint'
    )[lang],
    link: directsale_text_strings.find(
      (el) => el.key === 'offer_option_fixprice_link'
    )[lang],
    modalIndex: 0,
  },
  {
    value: 'auction',
    title: directsale_text_strings.find(
      (el) => el.key === 'offer_option_auction_title'
    )[lang],
    badge: directsale_text_strings.find(
      (el) => el.key === 'offer_option_auction_badge'
    )[lang],
    hint: directsale_text_strings.find(
      (el) => el.key === 'offer_option_auction_hint'
    )[lang],
    link: directsale_text_strings.find(
      (el) => el.key === 'offer_option_auction_link'
    )[lang],
    modalIndex: 1,
  },
];
export const getAttributeNamesByKey = (type, keys, lang, attributeContent) => {
  if (typeof keys === 'string') {
    const attributeData = attributeContent.find(
      (el) => el.type === type && el.translations.key === keys
    );

    if (attributeData) {
      return attributeData.translations[lang];
    } else {
      return keys;
    }
  }

  return keys.map((obj) => {
    const attributeData = attributeContent.find(
      (el) => el.type === type && el.translations.key === obj.name
    );

    if (attributeData) {
      obj.name = attributeData.translations[lang];
    }

    return obj;
  });
};

export const formatGeneralOptions = (general_text_strings, keyPrefix, lang) => {
  const options = [];

  for (let i = 1; i <= 4; i++) {
    const option = general_text_strings.find(
      (el) => el.key === `${keyPrefix}${i}`
    )[lang];
    options.push({ name: option, value: '' + i });
  }

  return options;
};

/* --- DATA FORMATTERS --- */

export const formatMakes = (makes) => {
  const formattedResult = makes.map((make) => ({
    name: make.name,
    value: make.brandCode,
  }));

  return formattedResult;
};
export const formatYears = () => {
  const formattedResult = [];
  const currentYear = new Date().getFullYear();

  for (let i = currentYear; i >= currentYear - 25; i--) {
    formattedResult.push({ name: i, value: i });
  }

  return formattedResult;
};
export const formatMonths = () => {
  const formattedResult = [];

  for (let i = 0; i < 12; i++) {
    formattedResult.push({
      name: (i < 9 ? '0' : '') + (i + 1),
      value: i,
    });
  }

  return formattedResult;
};
export const formatDistinctByField = (rawData, field, sortByName = false) => {
  const distinctElements = [
    ...new Set(rawData.map((element) => element[field])),
  ];
  const formattedResult = [];

  distinctElements.map((element) =>
    element
      ? formattedResult.push({
          name: element,
          value: element,
        })
      : null
  );

  if (sortByName) {
    return formattedResult.sort((a, b) =>
      sortHandler(a.name.toLowerCase(), b.name.toLowerCase())
    );
  }

  return formattedResult;
};
export const formatRelevantVehicles = (
  allVehicles,
  selectedParams,
  lang,
  attributeContent,
  generalStrings
) => {
  const filteredVehicles = allVehicles.filter((vehicle) => {
    let isExact = true;

    for (let key in selectedParams) {
      if (selectedParams[key] && vehicle[key] !== selectedParams[key]) {
        isExact = false;
        break;
      }
    }

    return isExact;
  });

  const formattedResult = filteredVehicles.map((vehicle) => {
    const {
      articleKey,
      brand,
      model,
      type,
      bodyType,
      hp,
      gear,
      fuel,
      productionDate,
      productionDateTo,
      firstRegistrationDate,
      price,
    } = vehicle;

    if (attributeContent && lang) {
      return {
        value: articleKey,
        title: `${brand} ${model} ${type} ${getAttributeNamesByKey(
          'bodyType',
          bodyType,
          lang,
          attributeContent
        )}`,
        hint: `${hp} ps | ${getAttributeNamesByKey(
          'gear',
          gear,
          lang,
          attributeContent
        )} | ${getAttributeNamesByKey(
          'fuel',
          fuel,
          lang,
          attributeContent
        )}<br/>
        ${extractContentString(
          generalStrings,
          'dashboard_car_card_production_date',
          lang
        )}: ${productionDate} - ${
          productionDateTo === '12/2100' && new Date().getFullYear() < 2090
            ? extractContentString(
                generalStrings,
                'dashboard_car_card_production_now',
                lang
              )
            : productionDateTo
        } | ${extractContentString(
          generalStrings,
          'dashboard_car_card_first_registration',
          lang
        )}: ${firstRegistrationDate.split('.').pop()}<br/>
        CHF ${numberWithSeparator(price)}`,
      };
    }

    return {
      value: articleKey,
      title: `${brand} ${model} ${type} ${bodyType}`,
      hint: `${hp} ps | ${gear} | ${fuel}`,
    };
  });

  return formattedResult;
};

export const formatEstimatedPrice = (price, condition, equipment, settings) => {
  if (!settings) return '';

  const getPrecentageValue = (key) => {
    const result = settings.find((el) => el.key === key);
    return result ? +result.value : 0;
  };

  const PERCENTAGES = {
    condition: {
      1: getPrecentageValue('ESTIMATED_PRICE_VERY_BAD_CONDITION_PERCENTAGE'),
      2: getPrecentageValue('ESTIMATED_PRICE_BAD_CONDITION_PERCENTAGE'),
      3: getPrecentageValue('ESTIMATED_PRICE_GOOD_CONDITION_PERCENTAGE'),
      4: getPrecentageValue('ESTIMATED_PRICE_VERY_GOOD_CONDITION_PERCENTAGE'),
    },
    equipment: {
      1: getPrecentageValue('ESTIMATED_PRICE_BASIC_EQUIPMENT_PERCENTAGE'),
      2: getPrecentageValue('ESTIMATED_PRICE_NORMAL_EQUIPMENT_PERCENTAGE'),
      3: getPrecentageValue('ESTIMATED_PRICE_HIGH_EQUIPMENT_PERCENTAGE'),
      4: getPrecentageValue('ESTIMATED_PRICE_VERY_HIGH_EQUIPMENT_PERCENTAGE'),
    },
  };
  const RANGE_PERCENTAGE_UP = settings.find(
    (el) => el.key === 'ESTIMATED_PRICE_RANGE_PERCENTAGE_UP'
  )
    ? +settings.find((el) => el.key === 'ESTIMATED_PRICE_RANGE_PERCENTAGE_UP')
        .value
    : 0;
  const RANGE_PERCENTAGE_DOWN = settings.find(
    (el) => el.key === 'ESTIMATED_PRICE_RANGE_PERCENTAGE_DOWN'
  )
    ? +settings.find((el) => el.key === 'ESTIMATED_PRICE_RANGE_PERCENTAGE_DOWN')
        .value
    : 0;
  const ADJUSTED_ESTIMATION_PRICE =
    (price *
      (100 +
        PERCENTAGES.condition[condition] +
        PERCENTAGES.equipment[equipment])) /
    100;
  const minPrice =
    Math.round(
      (ADJUSTED_ESTIMATION_PRICE * ((100 - RANGE_PERCENTAGE_DOWN) / 100)) / 100
    ) * 100;
  const maxPrice =
    Math.round(
      (ADJUSTED_ESTIMATION_PRICE * ((100 + RANGE_PERCENTAGE_UP) / 100)) / 100
    ) * 100;

  const formattedResult = `CHF ${numberWithSeparator(
    minPrice
  )} - CHF ${numberWithSeparator(maxPrice)}`;

  return formattedResult;
};

export const getRelevantOptions = (
  option,
  allVehicles,
  selectedOptions = {}
) => {
  // used for bodyType, fuel, gear and type
  const relevantVehicles = allVehicles.filter((vehicle) => {
    let isRelevant = true;

    for (let key in selectedOptions) {
      if (key === option) {
        break;
      }

      if (selectedOptions[key] && vehicle[key] !== selectedOptions[key]) {
        isRelevant = false;
        break;
      }
    }

    return isRelevant;
  });

  const relevantOptions = formatDistinctByField(relevantVehicles, option);

  return relevantOptions;
};

/* --- OTHER UTILS --- */

const sortHandler = (a, b) => {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
};

const numberWithSeparator = (number) =>
  number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '’');

export const LightenDarkenColor = (color, amt) => {
  var usePound = false;

  if (color.length === 4) {
    color += color.slice(-3);
  }

  if (color[0] == '#') {
    color = color.slice(1);
    usePound = true;
  }

  var num = parseInt(color, 16);

  var r = (num >> 16) + amt;

  if (r > 255) r = 255;
  else if (r < 0) r = 0;

  var b = ((num >> 8) & 0x00ff) + amt;

  if (b > 255) b = 255;
  else if (b < 0) b = 0;

  var g = (num & 0x0000ff) + amt;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
};

export const hexToRGB = (h) => {
  let r = 0,
    g = 0,
    b = 0;

  // 3 digits
  if (h.length == 4) {
    r = '0x' + h[1] + h[1];
    g = '0x' + h[2] + h[2];
    b = '0x' + h[3] + h[3];

    // 6 digits
  } else if (h.length == 7) {
    r = '0x' + h[1] + h[2];
    g = '0x' + h[3] + h[4];
    b = '0x' + h[5] + h[6];
  }

  return '' + +r + ',' + +g + ',' + +b;
};
